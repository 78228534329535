<template>
  <div class="card-body">
    <div class="row">
      <!-- Detalle Turno -->
      <div class="col-md-6 col-sm-12 col-xs-12">
        <div class="card card-info card-outline collapsed-card">
          <div class="card-header pt-2 pb-2">
            <h3 class="card-title">
              <i class="fa fa-truck text-secondary"></i> Turno
            </h3>
            <div class="card-tools">
              <button
                type="button"
                data-card-widget="collapse"
                data-toggle="tooltip"
                title="Collapse"
                class="btn btn-tool"
              >
                <i class="fas fa-plus"></i>
              </button>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <label for="id_turno_info" class="col-md-4 col-sm-4 col-xs-6"
                ><b>No:</b></label
              >
              <span
                id="id_turno_info"
                class="col-md-8 col-sm-8 col-xs-6 text-right"
                >{{ $parent.turno.id }}</span
              >
            </div>
            <div class="row">
              <label
                for="turno_info_fecha_ini"
                class="col-md-4 col-sm-4 col-xs-6"
                ><b>Fecha Inicio:</b></label
              >
              <span
                id="turno_info_fecha_ini"
                class="col-md-8 col-sm-8 col-xs-6 text-right"
                >{{ $parent.turno.fecha_ini }}</span
              >
            </div>
            <div class="row">
              <label
                for="turno_info_fecha_fin"
                class="col-md-4 col-sm-4 col-xs-6"
                ><b>Fecha Fin:</b></label
              >
              <span
                id="turno_info_fecha_fin"
                class="col-md-8 col-sm-8 col-xs-6 text-right"
                >{{ $parent.turno.fecha_fin }}</span
              >
            </div>
            <div class="row">
              <label
                for="turno_info_tipo_turno"
                class="col-md-4 col-sm-4 col-xs-6"
                ><b>Tipo Turno: </b></label
              >
              <span
                id="Clase_Viaje_info"
                class="col-md-8 col-sm-8 col-xs-6 text-right"
                >{{ $parent.turno.turno.nombre }}</span
              >
            </div>
            <div class="row">
              <label
                for="turno_info_tipo_turno"
                class="col-md-4 col-sm-4 col-xs-6"
                ><b>Tipo Inicio: </b></label
              >
              <span
                class="col-md-8 col-sm-8 col-xs-6 text-right"
                v-if="$parent.turno.tipo_inicio == 1"
                >Automatico</span
              >
              <span class="col-md-8 col-sm-8 col-xs-6 text-right" v-else
                >Manual</span
              >
            </div>
            <div class="row">
              <label
                for="turno_info_horas_turno"
                class="col-md-4 col-sm-4 col-xs-6"
                ><b>Horas: </b></label
              >
              <span
                id="turno_info_horas_turno"
                class="col-md-8 col-sm-8 col-xs-6 text-right"
                >{{ $parent.turno.horas_turno }}</span
              >
            </div>
            <div class="row">
              <label for="Estado_info" class="col-md-4 col-sm-4 col-xs-6"
                ><b>Estado: </b></label
              >
              <span
                id="Estado_info"
                class="col-md-8 col-sm-8 col-xs-6 text-right"
                >{{ $parent.turno.estadoTurno }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <!-- Detalle de la carga -->
      <div class="col-md-6 col-sm-12 col-xs-12">
        <div class="card card-info card-outline collapsed-card">
          <div class="card-header pt-2 pb-2">
            <h3 class="card-title">
              <i class="fa fa-align-justify text-secondary"></i> Detalle de
              Carga
            </h3>
            <div class="card-tools">
              <button
                type="button"
                data-card-widget="collapse"
                data-toggle="tooltip"
                title="Collapse"
                class="btn btn-tool"
              >
                <i class="fas fa-plus"></i>
              </button>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <label
                for="turno_info_operacion"
                class="col-md-4 col-sm-4 col-xs-6"
                ><b>Operacion: </b></label
              >
              <span
                id="turno_info_operacion"
                class="col-md-8 col-sm-8 col-xs-6 text-right"
                >{{ $parent.turno.operacion.Noperacion }}</span
              >
            </div>
            <div class="row">
              <label for="turno_info_ruta" class="col-md-4 col-sm-4 col-xs-6"
                ><b>Ruta: </b></label
              >
              <span
                id="turno_info_ruta"
                class="col-md-8 col-sm-8 col-xs-6 text-right"
                >{{ $parent.turno.ruta.nombre }}</span
              >
            </div>
            <div class="row">
              <label
                for="turno_info_producto"
                class="col-md-4 col-sm-4 col-xs-6"
                ><b>Producto: </b></label
              >
              <span
                id="turno_info_producto"
                class="col-md-8 col-sm-8 col-xs-6 text-right"
                >{{ $parent.turno.producto.nombre }}</span
              >
            </div>
            <div class="row">
              <label for="turno_info_bloque" class="col-md-4 col-sm-4 col-xs-6"
                ><b>Bloque: </b></label
              >
              <span
                id="turno_info_bloque"
                class="col-md-8 col-sm-8 col-xs-6 text-right"
                >{{ $parent.turno.bloque.nombre }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <!-- Detalle Unidad de transporte-->
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="card card-info card-outline collapsed-card">
          <div class="card-header pt-2 pb-2">
            <h3 class="card-title">
              <i class="fa fa-id-card text-secondary"></i> Unidad de Transporte
            </h3>
            <div class="card-tools">
              <button
                type="button"
                data-card-widget="collapse"
                data-toggle="tooltip"
                title="Collapse"
                class="btn btn-tool"
              >
                <i class="fas fa-plus"></i>
              </button>
            </div>
          </div>
          <div class="card-body">
            <!-- Información de la Empresa -->
            <div class="row">
              <label
                for="Identificacion_info"
                class="col-md-6 col-sm-6 col-xs-6"
                ><b>Empresa Transportadora: </b></label
              >
              <span
                id="Identificacion_info"
                class="col-md-6 col-sm-6 col-xs-6 text-right"
                >{{ $parent.turno.empresa.razon_social }}</span
              >
            </div>
            <br />
            <!-- Información del Conductor -->
            <div class="row">
              <div class="form-group col-md-3 col-sm-6 col-xs-12 text-center">
                <span><strong>Conductor</strong></span>
                <div>
                  <span v-if="$parent.turno.conductor"
                    >{{ $parent.turno.conductor.nombres }}
                    {{ $parent.turno.conductor.apellidos }}</span
                  >
                </div>
                <div>
                  <small
                    >Documento:
                    {{
                      $parent.turno.conductor
                        ? $parent.turno.conductor.numero_documento
                        : ""
                    }}</small
                  >
                </div>
                <div>
                  <small
                    >Tel:
                    {{
                      $parent.turno.conductor
                        ? $parent.turno.conductor.celular_principal
                        : ""
                    }}</small
                  >
                </div>
                <div
                  class="m-3"
                  v-if="
                    $parent.turno.conductor &&
                      $parent.turno.conductor.link_fotografia
                  "
                >
                  <a
                    :href="uri_docs + $parent.turno.conductor.link_fotografia"
                    target="_blank"
                    ><img
                      :src="uri_docs + $parent.turno.conductor.link_fotografia"
                      alt="Conductor"
                      class="rounded mx-auto d-block"
                      width="150"
                  /></a>
                </div>
              </div>
              <!-- Información del Conductor 2 -->
              <div class="form-group col-md-3 col-sm-6 col-xs-12 text-center">
                <span><strong>Conductor 2</strong></span>
                <div>
                  <span v-if="$parent.turno.conductor2"
                    >{{ $parent.turno.conductor2.nombres }}
                    {{ $parent.turno.conductor2.apellidos }}</span
                  >
                </div>
                <div>
                  <small
                    >Documento:
                    {{
                      $parent.turno.conductor2
                        ? $parent.turno.conductor2.numero_documento
                        : ""
                    }}</small
                  >
                </div>
                <div>
                  <small
                    >Tel:
                    {{
                      $parent.turno.conductor2
                        ? $parent.turno.conductor2.celular_principal
                        : ""
                    }}</small
                  >
                </div>
                <div
                  class="m-3"
                  v-if="
                    $parent.turno.conductor2 &&
                      $parent.turno.conductor2.link_fotografia
                  "
                >
                  <a
                    :href="uri_docs + $parent.turno.conductor2.link_fotografia"
                    target="_blank"
                    ><img
                      :src="uri_docs + $parent.turno.conductor2.link_fotografia"
                      alt="Conductor"
                      class="rounded mx-auto d-block"
                      width="150"
                  /></a>
                </div>
              </div>
              <!-- Información del Vehículo -->
              <div class="form-group col-md-3 col-sm-6 col-xs-12 text-center">
                <span><strong>Vehículo</strong></span>
                <div>
                  <span>{{
                    $parent.turno.vehiculo ? $parent.turno.vehiculo.placa : ""
                  }}</span>
                </div>
                <div
                  class="m-3"
                  v-if="
                    $parent.turno.vehiculo &&
                      $parent.turno.vehiculo.link_fotografia
                  "
                >
                  <a
                    :href="uri_docs + $parent.turno.vehiculo.link_fotografia"
                    target="_blank"
                    ><img
                      :src="uri_docs + $parent.turno.vehiculo.link_fotografia"
                      alt="Vehículo"
                      class="rounded mx-auto d-block"
                      width="150"
                  /></a>
                </div>
              </div>
              <!-- Información del Remolque -->
              <div
                v-if="$parent.turno.remolque"
                class="form-group col-md-3 col-sm-6 col-xs-12 text-center"
              >
                <span><strong>Remolque</strong></span>
                <div>
                  <span>{{ $parent.turno.remolque.placa }}</span>
                </div>
                <div class="m-3" v-if="$parent.turno.remolque.link_fotografia">
                  <a
                    :href="uri_docs + $parent.turno.remolque.link_fotografia"
                    target="_blank"
                    ><img
                      :src="uri_docs + $parent.turno.remolque.link_fotografia"
                      alt="Remolque"
                      class="rounded mx-auto d-block"
                      width="150"
                  /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Detalle Liquidacion-->
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="card card-info card-outline collapsed-card">
          <div class="card-header pt-2 pb-2">
            <h3 class="card-title">
              <i class="fa fa-calendar-plus"></i> Liquidacion
            </h3>
            <div class="card-tools">
              <button
                type="button"
                data-card-widget="collapse"
                data-toggle="tooltip"
                title="Collapse"
                class="btn btn-tool"
              >
                <i class="fas fa-plus"></i>
              </button>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-sm-2">
                    <div class="description-block">
                      <h5 class="description-header">
                        {{
                          $parent.turno.cant_viajes
                            ? $parent.turno.cant_viajes
                            : "Sin Viajes"
                        }}
                      </h5>
                      <span class="description-text text-secondary"
                        >Cantidad Viajes</span
                      >
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="description-block">
                      <h5 class="description-header">
                        {{ $parent.turno.TipoTarifa }}
                      </h5>
                      <span class="description-text text-secondary"
                        >Tipo Tarifa</span
                      >
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="description-block">
                      <h5 class="description-header">
                        {{
                          $parent.turno.det_tarifa &&
                          $parent.turno.det_tarifa.tif_tarifa &&
                          $parent.turno.det_tarifa.tif_tarifa.tarifario
                            ? $parent.turno.det_tarifa.tif_tarifa.tarifario
                                .nombre
                            : "Sin Asignar"
                        }}
                      </h5>
                      <span class="description-text text-secondary"
                        >TARIFARIO</span
                      >
                    </div>
                  </div>
                  <div class="col-sm-5" v-if="$parent.turno.tipo_tarifa == 2">
                    <div class="description-block">
                      <h5 class="description-header">
                        {{
                          $parent.turno.det_tarifa
                            ? $parent.turno.fijoTarifa
                            : "Sin Tarifa"
                        }}
                      </h5>
                      <span class="description-text text-secondary"
                        >VALOR TARIFA FIJO</span
                      >
                    </div>
                  </div>
                  <div class="col-sm-4" v-else>
                    <div class="description-block">
                      <h5 class="description-header">
                        {{
                          $parent.turno.det_tarifa
                            ? $parent.turno.valorDisp
                            : "Sin Tarifa"
                        }}
                      </h5>
                      <span class="description-text text-secondary"
                        >VALOR TARIFA DISPONIBLE</span
                      >
                    </div>
                  </div>
                  <div class="col-sm-2" v-if="$parent.turno.tipo_tarifa == 2">
                    <div class="description-block">
                      <h5 class="description-header">
                        {{
                          $parent.turno.det_tarifa
                            ? $parent.turno.fijoVariable
                            : "Sin Tarifa"
                        }}
                      </h5>
                      <span class="description-text text-secondary"
                        >VALOR TARIFA VARIABLE</span
                      >
                    </div>
                  </div>
                  <div class="col-sm-4" v-else>
                    <div class="description-block">
                      <h5 class="description-header">
                        {{
                          $parent.turno.det_tarifa
                            ? $parent.turno.valorOp
                            : "Sin Tarifa"
                        }}
                      </h5>
                      <span class="description-text text-secondary"
                        >VALOR TARIFA OPERATIVO</span
                      >
                    </div>
                  </div>
                  <div class="col-sm-5" v-if="$parent.turno.tipo_tarifa == 2">
                    <div class="description-block">
                      <h5 class="description-header">
                        {{
                          $parent.turno.km_final ? $parent.turno.km_final : 0
                        }}
                      </h5>
                      <span class="description-text text-secondary"
                        >KILOMETROS</span
                      >
                    </div>
                  </div>
                  <div class="col-sm-2" v-if="$parent.turno.tipo_tarifa == 2">
                    <div class="description-block">
                      <h5 class="description-header">
                        {{
                          $parent.turno.det_tarifa
                            ? $parent.turno.costoFijo
                            : "Sin Conciliar"
                        }}
                      </h5>
                      <span class="description-text text-secondary"
                        >COSTO TARIFA FIJO</span
                      >
                    </div>
                  </div>
                  <div class="col-sm-4" v-else>
                    <div class="description-block">
                      <h5 class="description-header">
                        {{
                          $parent.turno.det_tarifa
                            ? $parent.turno.costoOpera
                            : "Sin Conciliar"
                        }}
                      </h5>
                      <span class="description-text text-secondary"
                        >COSTO TARIFA OPERATIVA</span
                      >
                    </div>
                  </div>
                  <div class="col-sm-4" v-if="$parent.turno.tipo_tarifa == 2">
                    <div class="description-block">
                      <h5 class="description-header">
                        {{
                          $parent.turno.det_tarifa
                            ? $parent.turno.costoVariable
                            : "Sin Conciliar"
                        }}
                      </h5>
                      <span class="description-text text-secondary"
                        >COSTO TARIFA VARIABLE</span
                      >
                    </div>
                  </div>
                  <div class="col-sm-7" v-else>
                    <div class="description-block">
                      <h5 class="description-header">
                        {{
                          $parent.turno.det_tarifa
                            ? $parent.turno.costoDispo
                            : "Sin Conciliar"
                        }}
                      </h5>
                      <span class="description-text text-secondary"
                        >COSTO TARIFA DISPONIBLE</span
                      >
                    </div>
                  </div>
                  <div class="col-sm-4" v-if="$parent.turno.tipo_tarifa == 2">
                    <div class="description-block">
                      <h5 class="description-header">
                        {{
                          $parent.turno.det_tarifa
                            ? $parent.turno.valorTotalF
                            : "Sin Conciliar"
                        }}
                      </h5>
                      <span class="description-text text-secondary">TOTAL</span>
                    </div>
                  </div>
                  <div class="col-sm-2" v-else>
                    <div class="description-block">
                      <h5 class="description-header">
                        {{
                          $parent.turno.det_tarifa
                            ? $parent.turno.valorTotal
                            : "Sin Conciliar"
                        }}
                      </h5>
                      <span class="description-text text-secondary">TOTAL</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <!-- Información de Tiempo Operativo -->
            <div class="row">
              <label
                for="turno_info_operacion"
                class="col-md-2 col-sm-2 col-xs-6"
                ><b>Tiempo Operativo: </b></label
              >
              <span
                class="progress-bar progress-bar-striped bg-success"
                role="progressbar"
                style="width: 83%"
                aria-valuenow="10"
                aria-valuemin="0"
                aria-valuemax="100"
                >{{ $parent.turno.tiempo_operativo }}</span
              >
            </div>
            <br />
            <!-- Información de Tiempo Disponible -->
            <div class="row" v-if="$parent.turno.tipo_tarifa != 2">
              <label
                for="turno_info_operacion"
                class="col-md-2 col-sm-2 col-xs-6"
                ><b>Tiempo Disponible:</b></label
              >
              <span
                class="progress-bar progress-bar-striped bg-warning"
                role="progressbar"
                style="width: 83%"
                aria-valuenow="10"
                aria-valuemin="0"
                aria-valuemax="100"
                >{{ $parent.turno.tiempo_disponible }}</span
              >
            </div>
            <br />
            <!-- Información de Tiempo Indisponible -->
            <div class="row">
              <label
                for="turno_info_operacion"
                class="col-md-2 col-sm-2 col-xs-6"
                ><b>Tiempo Indisponible:</b></label
              >
              <span
                class="progress-bar progress-bar-striped bg-danger"
                role="progressbar"
                style="width: 83%"
                aria-valuenow="10"
                aria-valuemin="0"
                aria-valuemax="100"
                >{{ $parent.turno.tiempo_indisponible }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "TifTurnoInfoTurno",
  data() {
    return {
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },
};
</script>
